import { enablePageScroll, disablePageScroll, addScrollableTarget } from 'scroll-lock'

var $body = $('body')

const Navigation = {
  init () {
    var _ = this
    _.navToggle = $('#nav-toggle')

    // Navigation Toggle
    _.navToggle.on('click', function(){
      var $self = $(this)

      if ($body.hasClass('navigation-open')) {
        _.hide()
        $('#lang-selector-menu')[0].style.display = 'none';
      } else {
        disablePageScroll()
        $self.addClass('open')
        $body.addClass('navigation-open')
        $('#lang-selector-menu')[0].style.display = 'flex';
      }
      const $scrollableElement = document.querySelector('.navigation-panel-inner')
      addScrollableTarget($scrollableElement)
      return false
    })
  },
  hide () {
    var _ = this
    $body.removeClass('navigation-open')
    _.navToggle.removeClass('open')
    enablePageScroll()
  }
}

export default Navigation